@charset "UTF-8";
/*!
Theme Name: Monk Holding
Theme URI: https://github.com/monkstudio/unicorn-buns
Author: Jaclyn Tan
Author Email: jaclyn@monk.com.au
Author URI: http://monk.com.au
Description: Static HTML template
Version: 1.0.0
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Text Domain: http://www.monk.com.au

  .^====^. 
 =( ^--^ )= 
  /      \   /~  
+( |    | )// 


*/
.gform_ajax_spinner {
  display: none;
}

.gform_wrapper {
  margin-bottom: 1em;
}

.gform_wrapper .button[type="submit"] {
  appearance: none;
  border: none;
  border-radius: 2px;
  padding: 20px 40px;
  font-size: 16px;
  transition: background-color .1s ease-in-out;
  background-color: #1ab3d1;
  color: #fff;
  font-family: "Gentona-Medium", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 25.6px;
  font-size: 1.6rem;
  text-transform: uppercase;
  margin: 0 auto;
  display: table;
}

.gform_wrapper .button[type="submit"]:hover {
  background-color: #282828;
  cursor: pointer;
}

.gform_wrapper input[type="text"],
.gform_wrapper input[type="email"],
.gform_wrapper input[type="url"],
.gform_wrapper input[type="password"],
.gform_wrapper input[type="search"],
.gform_wrapper input[type="number"],
.gform_wrapper input[type="tel"],
.gform_wrapper input[type="range"],
.gform_wrapper input[type="date"],
.gform_wrapper input[type="month"],
.gform_wrapper input[type="week"],
.gform_wrapper input[type="time"],
.gform_wrapper input[type="datetime"],
.gform_wrapper input[type="datetime-local"],
.gform_wrapper input[type="color"],
.gform_wrapper textarea,
.gform_wrapper select {
  appearance: none;
  width: 100%;
  margin: 0;
  border: none;
  border-bottom: 3px solid #1ab3d1;
  background-color: #ededed;
  padding: 10px 50px;
  text-align: center;
  width: 100%;
  height: inherit;
  margin: auto;
  border-radius: 10px;
  color: #282828;
  font-size: 22.4px;
  font-size: 1.4rem;
  font-family: "Gentona-Medium", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .gform_wrapper input[type="text"],
  .gform_wrapper input[type="email"],
  .gform_wrapper input[type="url"],
  .gform_wrapper input[type="password"],
  .gform_wrapper input[type="search"],
  .gform_wrapper input[type="number"],
  .gform_wrapper input[type="tel"],
  .gform_wrapper input[type="range"],
  .gform_wrapper input[type="date"],
  .gform_wrapper input[type="month"],
  .gform_wrapper input[type="week"],
  .gform_wrapper input[type="time"],
  .gform_wrapper input[type="datetime"],
  .gform_wrapper input[type="datetime-local"],
  .gform_wrapper input[type="color"],
  .gform_wrapper textarea,
  .gform_wrapper select {
    padding: 10px;
  }
}

.gform_fields {
  padding: 0;
  list-style: none;
}

.gform_heading {
  margin-bottom: 1em;
}

.gform_body {
  margin-bottom: 0;
}

.gform_body .gfield {
  margin-bottom: 50px;
  list-style: none;
}

@media (max-width: 768px) {
  .gform_body .gfield {
    margin-bottom: 30px;
  }
}

.gform_body .gfield.gform_validation_container {
  display: none !important;
}

.gform_body .gfield_label {
  line-height: 1.6;
  font-family: "Gentona-Medium", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 22.4px;
  font-size: 1.4rem;
}

.gform_body .gfield_required {
  position: relative;
  top: -2px;
  right: -2px;
  color: #1fa3c7;
}

.gform_body .gfield_error .validation_message {
  background-color: #1fa3c7;
  color: #FFF;
  font-size: 80%;
  padding: 5px 15px;
}

.gform_body .gfield_checkbox, .gform_body .gfield_radio {
  list-style: none;
  margin: 0;
  padding: 0;
}

.gform_body .gfield_checkbox > li, .gform_body .gfield_radio > li {
  padding-left: 0;
  margin-left: 0;
}

.gform_body .gfield_checkbox > li label, .gform_body .gfield_radio > li label {
  margin-left: 0;
}

.gform_body .ginput_container_textarea {
  line-height: 0;
}

.gform_body .ginput_complex {
  display: table;
  table-layout: fixed;
  width: 100%;
  box-sizing: border-box;
}

.gform_body .ginput_complex > span {
  display: table-cell;
  vertical-align: top;
  width: 100%;
}

.gform_body .ginput_complex > span:last-of-type {
  padding-right: 0;
}

.gform_body .ginput_complex > .ginput_full {
  display: block;
  width: 100%;
  padding-right: 0;
  margin-bottom: 10px;
}

.gform_body .ginput_complex > .ginput_left {
  display: block;
  float: left;
  width: 47%;
  clear: left;
  margin-bottom: 10px;
  min-height: 90px;
}

.gform_body .ginput_complex > .ginput_right {
  display: block;
  float: right;
  width: 47%;
  clear: right;
  margin-bottom: 10px;
}

.gform_body .ginput_complex label {
  display: block;
}

@media (max-width: 768px) {
  .gform_body .ginput_complex label {
    line-height: 1;
    font-size: 80%;
  }
}

.gform_body .validation_message {
  background-color: #1fa3c7;
  color: #FFF;
  padding: 5px;
}

.gform_page_footer {
  background: #ededed;
}

.gform_next_button {
  float: right;
}

.gform_prev_button {
  float: left;
}

.gform .validation_error {
  font-size: 80%;
  margin: 1.5em 0;
}

.validation_error {
  font-weight: bold;
  text-align: center;
  color: #bf0421;
  line-height: 1.1;
  margin-bottom: 30px;
}

.gform_ajax_spinner {
  display: none;
}

/* Credit Card */
@font-face {
  font-family: 'icomoon';
  src: url("webfonts/icomoon.eot?pvuvir");
  src: url("webfonts/icomoon.eot?pvuvir#iefix") format("embedded-opentype"), url("webfonts/icomoon.ttf?pvuvir") format("truetype"), url("webfonts/icomoon.woff?pvuvir") format("woff"), url("webfonts/icomoon.svg?pvuvir#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"]:before, [class*=" icon-"]:before, article.hentry.sticky:before, .icon-after:after, .ls-nav-right a:before, .ls-nav-left a:before, .wc_payment_method label:before, label[for="stripe-card-number"]:after, label[for="stripe-card-cvc"]:after, .ui-icon:after, .ui-icon:before, .gform_card_icon_container div:before, .ginput_card_security_code_icon:before {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon:before {
  margin-right: .25em;
}

.icon-replacment:before {
  margin-right: 0;
}

.icon-search:before {
  content: "\e900";
}

.icon-pin:before, article.hentry.sticky:before {
  content: "\e906";
}

.icon-google-plus:before {
  content: "\e901";
}

.icon-instagram:before {
  content: "\e902";
}

.icon-linkedin:before {
  content: "\e903";
}

.icon-facebook:before {
  content: "\e904";
}

.icon-twitter:before {
  content: "\e905";
}

.icon-rss:before {
  content: "\e907";
}

.icon-new-window:before, .content .icon-new-window:after {
  content: "\e908";
}

.icon-pinterest:before {
  content: "\e604";
}

.icon-vimeo:before {
  content: "\e909";
}

.icon-youtube:before {
  content: "\e90a";
}

.icon-calendar:before {
  content: "\e918";
}

.icon-arrow-right:before, .ls-nav-right a:before, .ui-icon-circle-triangle-e:after {
  content: "\e994";
}

.icon-arrow-left:before, .ls-nav-left a:before, .ui-icon-circle-triangle-w:before {
  content: "\e995";
}

.icon-after:before {
  content: "";
  display: none;
}

.icon-after:after {
  margin-left: .25em;
}

.ginput_container_creditcard {
  background-color: #ededed;
  padding: 20px;
  border-radius: 10px;
}

.ginput_container_creditcard input, .ginput_container_creditcard select {
  background-color: #FFF !important;
  width: 48%;
  display: inline-block;
  margin: 10px 0;
  padding: 10px 20px;
}

.ginput_cardinfo_left {
  width: 68%;
}

.gform_card_icon_container div:before {
  position: absolute;
  left: 0;
  top: 0;
  text-indent: 0;
}

.gform_card_icon_container div {
  font-size: 25.6px;
  font-size: 1.6rem;
  float: left;
  text-indent: -99em;
  position: relative;
  display: block;
  width: 1.5em;
  color: #282828;
  display: block;
  margin: 0 0 20px;
}

@media (max-width: 992px) {
  .gform_card_icon_container div {
    font-size: 22.4px;
    font-size: 1.4rem;
  }
}

.ginput_card_security_code_icon:before {
  content: "\e911";
}

.icon-cc-paypal:before {
  content: "\e913";
}

.gform_card_icon_amex:before {
  content: "\e914";
}

.gform_card_icon_discover:before {
  content: "\e915";
}

.gform_card_icon_mastercard:before {
  content: "\e916";
}

.gform_card_icon_visa:before {
  content: "\e917";
}

.ginput_cardinfo_left, .ginput_cardinfo_right {
  float: left;
}

.ginput_cardinfo_right {
  float: right;
  width: 30%;
  margin-top: 10px;
}

span.ginput_card_security_code_icon {
  font-size: 1.5em;
  float: left;
  color: #282828;
  line-height: 1.2;
}

.gfield_creditcard_warning_message {
  background: #bf0421;
  color: white;
  padding: 1em .75em;
  border-radius: 10px;
  font-size: 80%;
  margin-bottom: 2em;
  line-height: 1.4;
}

.gfield_error .ginput_container_creditcard label {
  color: black;
}

@media (max-width: 992px) {
  .gfield_error .ginput_container_creditcard label {
    font-size: 70%;
    margin: 5px 0;
    line-height: 1.1;
  }
}

.ginput_container_creditcard .ginput_full {
  clear: both;
  display: block;
}

.field_sublabel_above .ginput_container_creditcard .ginput_full:first-of-type {
  margin-bottom: 2em;
}

/*! 
♡♡♡♡♡♡♡♡♡♡♡
♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥
Donation form
♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥
♡♡♡♡♡♡♡♡♡♡♡
*/
#gform_5 {
  width: 70%;
  margin: auto;
  margin-top: 50px;
}

@media (max-width: 992px) {
  #gform_5 {
    width: 100%;
    margin-top: 20px;
  }
}

#gform_5 ul {
  margin: 0;
  list-style: none;
  text-align: center;
}

#gform_5 #field_5_6 > label {
  color: #1fa3c7;
  font-size: 28.8px;
  font-size: 1.8rem;
}

#gform_5 #field_5_6 ul {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#gform_5 #field_5_6 input[type="radio"] {
  display: none;
}

#gform_5 #field_5_6 input[type="radio"] + label {
  color: #a1a1a1;
  background-color: #ededed;
  padding: 40px;
  display: table;
  font-size: 32px;
  font-size: 2rem;
  font-family: "Gentona-Medium", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

#gform_5 #field_5_6 input[type="radio"]:checked + label {
  background-color: #037a9f;
  color: #FFF;
}

#gform_5 #field_5_6 input[type="radio"] + label,
#gform_5 #field_5_6 input[type="radio"]:checked + label {
  transition: background-color 0.2s linear;
}

#gform_5 #field_5_6 #input_5_6 li:first-child label {
  border-radius: 15px 0 0 15px;
}

#gform_5 #field_5_6 #input_5_6 li:last-child label {
  border-radius: 0 15px 15px 0;
}

#gform_5 #field_5_5 {
  display: inline-block;
}

@media (max-width: 992px) {
  #gform_5 #field_5_5 {
    display: block;
  }
}

#gform_5 #field_5_5 #input_5_5 {
  display: flex;
  align-items: center;
  justify-content: center;
}

#gform_5 #field_5_5 input[type="radio"] {
  display: none;
}

#gform_5 #field_5_5 input[type="radio"] + label {
  padding: 20px 30px;
  display: table;
  font-size: 25.6px;
  font-size: 1.6rem;
  font-family: "Gentona-Medium", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: #ededed;
}

@media (max-width: 992px) {
  #gform_5 #field_5_5 input[type="radio"] + label {
    padding: 15px;
    font-size: 22.4px;
    font-size: 1.4rem;
  }
}

#gform_5 #field_5_5 input[type="radio"]:checked + label {
  background-color: #037a9f !important;
  color: #FFF;
}

#gform_5 #field_5_5 input[type="radio"] + label,
#gform_5 #field_5_5 input[type="radio"]:checked + label {
  transition: background-color 0.2s linear;
}

#gform_5 #field_5_5 #input_5_5 li:first-child label {
  border-radius: 15px 0 0 15px;
}

#gform_5 #field_5_5 #input_5_5 li:last-child label {
  border-radius: 0 15px 15px 0;
}

#gform_5 #field_5_5 #input_5_5 li:nth-child(odd) label {
  background-color: #d4d4d4;
}

#gform_5 #field_5_7 {
  display: inline-block;
  margin: 10px;
}

#gform_5 #field_5_7 input {
  width: 150px;
  border-radius: 15px;
  padding: 18px 20px;
}

@media (max-width: 768px) {
  #gform_5 #field_5_7 input {
    padding: 10px;
  }
}

#gform_5 #field_5_8 {
  font-weight: bold;
  font-size: 32px;
  font-size: 2rem;
  font-family: "Gentona-Medium", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  border-bottom: 3px solid #282828;
  padding: 0 30px 20px 30px;
  display: table;
  margin: 0 auto 20px auto;
}

#gform_5 #field_5_8 label {
  margin: 20px auto;
  display: table;
}

.main-content.fw {
  width: 100%;
}

hr {
  margin: 60px 0;
  border: 3px solid #ededed;
}
