.gform_ajax_spinner {
    display: none;
}
// The class .gform isn't actually used by Gravity Forms but acts as a prefix for all it's parent elements. Thus serves here to simply enclose the css structure.
.gform {
    // The encapsulating wrapping div for all forms. So far I used it to target common elements that would be used form-wide such as buttons and input tags.
    &_wrapper {
        margin-bottom: 1em;
        // General class assigned to all button & input[type="submit"] tags in the form
        .button {
            // @extend .btn;
            &[type="submit"] {
                @extend .btn !optional;
                appearance: none;
                border: none;
                border-radius: 2px;
                padding:20px 40px;
                font-size: 16px;
                transition: background-color .1s ease-in-out;
                background-color: #1ab3d1;
                color: #fff;
                font-family: $brand-font;
                font-weight: bold;
                @include font-size(1.6);
                text-transform: uppercase;
                margin: 0 auto;
                display: table;

                &:hover {
                    background-color: $brand-darkgrey;
                    cursor: pointer;
                }

            }
        }
        // Target all typing input tags and extend Bootstrap's .form-control class to all of them. Also prevent browsers from overriding our styles by setting appearance to none.
        input[type="text"],
        input[type="email"],
        input[type="url"],
        input[type="password"],
        input[type="search"],
        input[type="number"],
        input[type="tel"],
        input[type="range"],
        input[type="date"],
        input[type="month"],
        input[type="week"],
        input[type="time"],
        input[type="datetime"],
        input[type="datetime-local"],
        input[type="color"],
        textarea,
        select {
            appearance: none;
            width: 100%;
            margin: 0;
            border: none;
            border-bottom: 3px solid #1ab3d1;
            background-color: $brand-lightgrey;
            padding: 10px 50px;
            text-align: center;
            width: 100%;
            height: inherit;
            margin: auto;
            border-radius: 10px;
            color: $brand-darkgrey;
            @include font-size(1.4);
            font-family: $brand-font;
            box-sizing: border-box;
            @include media("<=tablet") {
                padding: 10px;
            }
        }
        textarea {
            //            margin-bottom: -6px;
        }
    }
    // On the off chance the form prints a ul list, target .gform_fields to make sure the default <ul> styles arn't applied.
    &_fields {
        padding: 0;
        //        margin: 1em;
        list-style: none;
    }
    // The wrapping div for the form's title & description.
    &_heading {
        margin-bottom: 1em;
    }
    // The forms title
    &_title {}
    // The forms description
    &_description {}
    // The wrapping div for the form's actual fields.
    &_body {
        margin-bottom: 0;
        .gsection {
            &_title {}
            &_description {
                @extend .help-block !optional;
            }
        }
        .gfield {
            margin-bottom: 50px;
            list-style: none;

            @include media("<=tablet") {
                margin-bottom: 30px; 
            }
            &.gform_validation_container {
                display: none !important;
            }
            &_label {
                line-height: 1.6;
                font-family: $brand-font;
                font-weight: normal;
                @include font-size(1.4);
            }
            &_required {
                position: relative;
                top: -2px;
                right: -2px;
                color: $brand-primary;
            }
            &_error {
                .validation_message {
                    @extend .alert-danger !optional;
                    background-color: $brand-primary;
                    color: #FFF;
                    font-size: 80%;
                    //                    border-radius: 3px;
                    padding: 5px 15px;
                    &.instruction {
                        @extend .alert-info !optional;
                    }
                }
            }
            &_html {}
            &_checkbox,
            &_radio {
                list-style: none;
                margin: 0;
                padding: 0;
                > li {
                    //@extend .checkbox;
                    padding-left: 0;
                    margin-left: 0;
                    label {
                        margin-left: 0;
                    }
                }
            }
        }
        .ginput {
            &_container {
                &_textarea {
                    line-height: 0;
                }
            }
            &_complex {
                display: table;
                table-layout: fixed;
                width: 100%;
                box-sizing: border-box;
                // @include clearfix;
                > span {
                    display: table-cell;
                    vertical-align: top;
                    // padding-right: 15px;
                    width: 100%;
                    &:last-of-type {
                        padding-right: 0;
                    }
                }
                > .ginput {
                    &_full {
                        display: block;
                        width: 100%;
                        padding-right: 0;
                        margin-bottom: 10px;
                    }
                    &_left {
                        display: block;
                        float: left;
                        width: 47%;
                        clear: left;
                        margin-bottom: 10px;
                        min-height: 90px;
                    }
                    &_right {
                        display: block;
                        float: right;
                        width: 47%;
                        clear: right; 
                        margin-bottom: 10px;
                    }
                }

                label {
                    display: block;

                    @include media("<=tablet") {
                        line-height: 1;
                        font-size: 80%;
                    }
                }
            }
        }
        .validation_message {
            @extend .alert !optional;
            background-color: $brand-primary;
            color: #FFF;
            padding: 5px;
        }
    }
    &_page_footer {
        background: $brand-lightgrey;
        .button {
            @extend .btn-link !optional;
        }
        // @include clearfix;
    }
    &_next_button {
        float: right;
    }
    &_prev_button {
        float: left;
    }
    &_footer {};
    .validation_error {
        font-size: 80%;
        margin: 1.5em 0;
    }
    
}

.validation_error {
    font-weight: bold;
    text-align: center;
    color: #bf0421;
    line-height: 1.1;
    margin-bottom: 30px;
}

.gform_ajax_spinner {
    display: none;
}



/* Credit Card */
@font-face {
    font-family: 'icomoon';
    src: url('webfonts/icomoon.eot?pvuvir');
    src: url('webfonts/icomoon.eot?pvuvir#iefix') format('embedded-opentype'), url('webfonts/icomoon.ttf?pvuvir') format('truetype'), url('webfonts/icomoon.woff?pvuvir') format('woff'), url('webfonts/icomoon.svg?pvuvir#icomoon') format('svg');
    font-weight: normal;
    font-style: normal
}


[class^="icon-"]:before,[class*=" icon-"]:before, article.hentry.sticky:before, .icon-after:after, .ls-nav-right a:before, .ls-nav-left a:before, .wc_payment_method label:before, label[for="stripe-card-number"]:after, label[for="stripe-card-cvc"]:after, .ui-icon:after, .ui-icon:before, .gform_card_icon_container div:before, .ginput_card_security_code_icon:before {font-family:'icomoon' !important;speak:none;font-style:normal;font-weight:normal;font-variant:normal;text-transform:none;line-height:1;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}

.icon:before {margin-right:.25em;}
.icon-replacment:before {margin-right:0;}

.icon-search:before {
    content: "\e900";
}
.icon-pin:before, article.hentry.sticky:before {
    content: "\e906";
}
.icon-google-plus:before {
    content: "\e901";
}
.icon-instagram:before {
    content: "\e902";
}
.icon-linkedin:before {
    content: "\e903";
}
.icon-facebook:before {
    content: "\e904";
}
.icon-twitter:before {
    content: "\e905";
}
.icon-rss:before {
    content: "\e907";
}
.icon-new-window:before, .content .icon-new-window:after {
    content: "\e908";
}
.icon-pinterest:before {
    content: "\e604";
}
.icon-vimeo:before {
    content: "\e909";
}
.icon-youtube:before {
    content: "\e90a";
}
.icon-calendar:before {
    content: "\e918";
}

.icon-arrow-right:before, .ls-nav-right a:before, .ui-icon-circle-triangle-e:after {
    content: "\e994";
}
.icon-arrow-left:before, .ls-nav-left a:before, .ui-icon-circle-triangle-w:before {
    content: "\e995";
}

//.icon-replacement, .ls-nav-right a, .ls-nav-left a, .ui-icon-circle-triangle-e, .ui-icon-circle-triangle-w {text-indent:-999em; overflow:hidden; display:block; position:relative;}
//.icon-replacement:before, .ls-nav-right a:before, .ls-nav-left a:before, .ui-icon-circle-triangle-e:after, .ui-icon-circle-triangle-w:before {position:absolute; left:0; top:0; text-indent:0; width:100%; text-align:center;}
//button.icon-search.icon-replacement:before {width:auto;}

.icon-after:before {content:""; display:none;}
.icon-after:after {margin-left:.25em;}


.ginput_container_creditcard {
    background-color: $brand-lightgrey;
    padding: 20px;
    border-radius: 10px;
}
.ginput_container_creditcard input, .ginput_container_creditcard select {
    background-color: #FFF !important;
    width: 48%;
    display: inline-block;
    margin: 10px 0;
    padding: 10px 20px;
}
.ginput_cardinfo_left {
    width: 68%;
}

.gform_card_icon_container div:before {position:absolute; left:0; top:0; text-indent:0;}

.gform_card_icon_container div {
    @include font-size(1.6);
    float: left;
    text-indent: -99em;
    position: relative;
    display: block;
    width: 1.5em;
    color:$brand-darkgrey;
    display: block;
    margin: 0 0 20px;

    @include media("<=laptop") {
        @include font-size(1.4);
    }
}


.ginput_card_security_code_icon:before {
    content: "\e911";
}
.icon-cc-paypal:before {
    content: "\e913";
}
.gform_card_icon_amex:before {
    content: "\e914";
}
.gform_card_icon_discover:before {
    content: "\e915";
}
.gform_card_icon_mastercard:before {
    content: "\e916";
}
.gform_card_icon_visa:before {
    content: "\e917";
}


.ginput_cardinfo_left, .ginput_cardinfo_right {
    float: left;
}
.ginput_cardinfo_right {
    float: right;
    width: 30%;
    margin-top: 10px;
}

span.ginput_card_security_code_icon {
    font-size: 1.5em;
    float: left;
    color: $brand-darkgrey;
    line-height: 1.2;
}

.gfield_creditcard_warning_message {
    background: #bf0421;
    color: white;
    padding: 1em .75em;
    border-radius: 10px;
    font-size: 80%;
    margin-bottom: 2em;
    line-height: 1.4;
}
.gfield_error .ginput_container_creditcard label {
    color:black;
    @include media("<=laptop") {
        font-size:70%;
        margin: 5px 0;
        line-height: 1.1;
    }
}

.ginput_container_creditcard .ginput_full {
    clear: both;
    display: block;
}

.field_sublabel_above .ginput_container_creditcard .ginput_full:first-of-type {
    margin-bottom: 2em;
}




/*! 
♡♡♡♡♡♡♡♡♡♡♡
♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥
Donation form
♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥
♡♡♡♡♡♡♡♡♡♡♡
*/
#gform_5 {
    width: 70%;
    margin: auto;
    margin-top: 50px;

    @include media("<=laptop") {
        width: 100%;
        margin-top: 20px;
    }   

    ul {
        margin: 0;
        list-style: none;
        text-align: center;
    }
    #field_5_6 {
        >label {
            color: $brand-primary;
            @include font-size(1.8);
        }
        ul {
            margin-top: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        input[type="radio"] {
            display:none;

            & + label {
                color: darken($brand-lightgrey,30%);
                background-color: $brand-lightgrey;
                padding: 40px;
                display: table;
                @include font-size(2);
                font-family: $brand-font;
            }
            &:checked + label { 
                background-color:$brand-secondary;
                color: #FFF;
            }

            & + label,
            &:checked + label {
                transition:background-color 0.2s linear;
            }
        }

        #input_5_6 {
            li {
                &:first-child {
                    label {
                        border-radius: 15px 0 0 15px;
                    }
                }
                &:last-child {
                    label {
                        border-radius: 0 15px 15px 0;
                    }
                }
            }
        }
    }

    #field_5_5 {
        display: inline-block;

        @include media("<=laptop") {
            display: block;
        }
        #input_5_5 {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        input[type="radio"] {
            display:none;

            & + label {
                padding: 20px 30px; 
                display: table;
                @include font-size(1.6);
                font-family: $brand-font;
                background-color: $brand-lightgrey;

                @include media("<=laptop") {
                    padding: 15px;
                    @include font-size(1.4);
                }
            }
            &:checked + label { 
                background-color:$brand-secondary !important; 
                color: #FFF;
            }
            

            & + label,
            &:checked + label {
                transition:background-color 0.2s linear;
            }
        }

        #input_5_5 {
            li {
                &:first-child {
                    label {
                        border-radius: 15px 0 0 15px;
                    }
                }

                &:last-child {
                    label {
                        border-radius: 0 15px 15px 0;
                    }
                    
                }

                &:nth-child(odd) {
                    label {
                        background-color: darken($brand-lightgrey, 10%);
                    }
                }

        
            }
            
        }
        
    }

    #field_5_7 { 
        display: inline-block;
        margin: 10px;

        input {
            width: 150px;
            border-radius: 15px;
            padding: 18px 20px;

            @include media("<=tablet") {
                padding: 10px;
            }
        }
    }

    #field_5_8 {

        label {
            margin:20px auto;
            display: table;
        }
        font-weight: bold;
        @include font-size(2);
        font-family: $brand-font;
        border-bottom: 3px solid $brand-darkgrey;
        padding: 0 30px 20px 30px;
        display: table;
        margin:  0 auto 20px auto;
    }
}