//colours
//common
$brand-lightgrey: #ededed;
$brand-darkgrey: #282828; 

//brand
$brand-primary: #1fa3c7;  
$brand-secondary:#037a9f;
$brand-placeholder-color:$brand-lightgrey;

//fonts
$brand-font: 'Gentona-Medium', "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
$brand-heading: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
